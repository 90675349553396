import React from 'react'
import { graphql } from 'gatsby'
import { getHero } from '../components/contentfulPage'
import Layout from '../components/layout'
import ContentfulText from '../components/contentfulText'
import CustomerFeedbackHero from '../components/heroes/customerFeedbackHero'
import RelatedPostCard from '../components/cards/relatedPostCard'
import styled from 'styled-components'
import { Subheading } from '../components/typography/headings'
import Grid from '../components/cards/grid'
import GridItem from '../components/cards/gridItem'
import Container from '../components/container'
import Link from '../components/links/link'
import buttonStyles from '../styles/mixins/button'
import ContentfulRichText from '../components/contentfulRichText'
import { ImageWrap } from '../components/heroes/splitHero'
import BackgroundImage from '../components/backgroundImage'
import textContent from '../components/textContent'
import { MicrosoftClarityScript } from '../lib/ms-clarity'

const Wrapper = styled.div`
  margin-top: 50px;
`

const StyledContainer = styled(Container)`
  padding-top: 3em;
  padding-bottom: 3em;
  overflow: hidden;

  &.no-vertical-padding {
    padding-top: 0;
    padding-bottom: 0;
  }

  &.no-padding-bottom {
    padding-bottom: 0;
  }

  &.overflow-visible {
    overflow: visible;
  }
`

const StyledTextContent = styled(textContent)`
  margin-bottom: 3em;
`

const LinkButton = styled(Link).attrs(props => ({
  to: props.to,
  children: props.children,
}))`
  ${buttonStyles}
`

const StyledHeroSection = styled(Container)(
  ({ theme: { breakpoints } }) => `
  padding: 0;

  &:not(:first-child) {
    padding-top: 3em;
  }

  @media (min-width: ${breakpoints.lg.min}) {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    
    &.reverse {
      flex-flow: row-reverse nowrap;
    }

    ${ImageWrap},
    ${ImageWrap} + ${HeroSectionBody} {
      margin-bottom: 0;
    }

    ${ImageWrap} + ${HeroSectionBody} {
      width: calc(50% - 1em);
    }
    ${ImageWrap} {
      width: calc(50% - 1em);
    }
  }
`
)

const HeroSectionBody = styled.div`
  &.links-as-buttons {
    a {
      text-decoration: none;
      ${buttonStyles}
    }
  }
`

const PinkWrapper = styled.div(
  ({ theme }) => `
  background-color: ${theme.colors.pinkLight};
`
)

const LeadooForm = () => {
  return (
    <script
      type="text/javascript"
      async
      src="https://bot.leadoo.com/bot/visual.js?code=281c99bb#seamless"
    ></script>
  )
}

const parseCards = cards =>
  cards &&
  cards.reduce(
    (acc, curr) => {
      if (curr.__typename === 'ContentfulArticle') {
        acc.articles.push(curr)
      } else if (curr.__typename === 'ContentfulHeroSection') {
        acc.heroes.push(curr)
      }
      return acc
    },
    { heroes: [], articles: [] }
  )

const InvestingIntroductionPage = ({
  data: { page, customerExperiencePage },
}) => {
  const image = page.image && `https:${page.image.file.url}`
  const {
    customerExperienceStatsShortTitle: shortTitle,
    customerExperienceStatsLead: { customerExperienceStatsLead: statsLead },
    customerExperienceStats: { raw: statsRawText },
    customerExperienceStatsInfo: { customerExperienceStatsInfo: statsInfo },
  } = customerExperiencePage

  const { heroes, articles } = parseCards(page.cards) || {}

  return (
    <Layout
      title={page.title}
      description={page.description.description}
      image={image}
      hero={getHero(page)}
      shareable={page.shareable}
    >
      <Wrapper>
        <StyledContainer
          containerWidth="content"
          className="overflow-visible no-vertical-padding"
        >
          <StyledTextContent>
            <ContentfulText text={page.body} />
          </StyledTextContent>
        </StyledContainer>
        <CustomerFeedbackHero
          shortTitle={shortTitle}
          statsLead={statsLead}
          statsRawText={statsRawText}
          statsInfo={statsInfo}
          centerTitle={false}
        />
        <PinkWrapper>
          <StyledContainer
            containerWidth="content"
            className="no-padding-bottom"
          >
            {heroes?.length > 0 &&
              heroes.map((hero, idx) => (
                <StyledHeroSection
                  className={idx % 2 === 0 ? 'reverse' : ''}
                  key={hero.heading}
                >
                  {!!hero.image && (
                    <ImageWrap>
                      <BackgroundImage
                        image={hero.image.gatsbyImageData}
                        alt={hero.image.title || ''}
                      />
                    </ImageWrap>
                  )}
                  <HeroSectionBody>
                    <Subheading as="h2" headingSize="1">
                      {hero.heading}
                    </Subheading>
                    {hero.content?.raw && (
                      <ContentfulRichText
                        richText={{ raw: hero.content.raw }}
                      />
                    )}
                  </HeroSectionBody>
                </StyledHeroSection>
              ))}
          </StyledContainer>
          <LeadooForm />
        </PinkWrapper>
        <StyledContainer containerWidth="content">
          {articles?.length > 0 && (
            <>
              <Subheading as="h2" headingSize="1">
                Sijoittamalla kohti omannäköistä arkea
              </Subheading>
              <Grid>
                {articles.map(card => (
                  <GridItem itemsPerRow={3} key={card.slug}>
                    <RelatedPostCard
                      card={{ ...card, body: null }}
                      headingSize="5"
                      bgColor="white"
                    />
                  </GridItem>
                ))}
              </Grid>
              <LinkButton to="/sisallot">Katso lisää</LinkButton>
            </>
          )}
        </StyledContainer>
      </Wrapper>
    </Layout>
  )
}

export function Head() {
  return <MicrosoftClarityScript />
}

export default InvestingIntroductionPage

export const query = graphql`
  query ($slug: String!) {
    page: contentfulPage(slug: { eq: $slug }) {
      ...contentfulPageInformation
    }

    customerExperiencePage: contentfulCustomerExperiencePage(
      slug: { eq: "alexandria-kokemuksia" }
    ) {
      customerExperienceStatsLead {
        customerExperienceStatsLead
      }
      customerExperienceStatsInfo {
        customerExperienceStatsInfo
      }
      customerExperienceStatsShortTitle
      customerExperienceStats {
        raw
      }
    }
  }
`
