import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import hexToRgba from 'hex-to-rgba'
import styled from 'styled-components'
import CardCarousel from './cardCarousel'
import { Heading } from '../typography/headings'
import CtaButton from '../buttons/ctaButton'
import CustomerExperienceCard from './customerExperienceCard'
import { ReactComponent as ArrowRightIcon } from '../../images/arrow-right.svg'

const CXSection = styled.div(
  ({ theme: t }) => `
  display: flex;
  padding: 20px 0 25px 30px;
  background-color: ${hexToRgba(t.colors.pink, 0.5)};
  align-items: center;
  overflow: hidden;

  @media screen and (max-width: ${t.breakpoints.md.max}) {
    padding: 23px 17px 20px 17px;
  }
`
)

const Wrapper = styled.div(
  ({ theme: t }) => `
  display: flex;
  margin: 0 auto;
  overflow: hidden;
  width: 100%;
  gap: 100px;
  padding-left: 100px;

  @media screen and (max-width: ${t.breakpoints.xl.min}) {
    padding-left: 50px;
    gap: 50px;
  }

  @media screen and (max-width: ${t.breakpoints.md.max}) {
    flex-direction: column;
    padding-left: 0;
    gap: 26px;
  }
`
)

const LeftWrapper = styled.div(
  ({ theme: t }) => `
  display: flex;
  width: 50%;

  @media screen and (max-width: ${t.breakpoints.md.max}) {
    width: 100%;
  }
`
)

const TextContent = styled.div(
  ({ theme: t }) => `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: auto;
  gap: 50px;

  @media screen and (max-width: ${t.breakpoints.md.max}) {
    align-items: flex-start;
    margin-left: 0;
    gap: 30px;
  }
`
)

const Title = styled(Heading)(
  ({ theme: t }) => `
	width: 483px;
	height: 127px;
  margin: 0;
	font-size: 60px;
	font-weight: 600;
	line-height: 70px;

  @media screen and (max-width: ${t.breakpoints.md.max}) {
    width: 100%;
    height: auto;
    font-size: 38px;
    line-height: 42px;
  }
`
)

const CTAButton = styled(CtaButton)(
  ({ theme: t }) => `
  display: flex;
  height: 45px;
  padding: 0 20px;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  border-width: 2px;
  background-color: ${t.colors.primaryColor} !important;
  color: ${t.colors.white} !important;
  border-color: ${t.colors.primaryColor} !important;
  gap: 15px;

  svg {
    min-width: 25px;
    path {
      fill: ${t.colors.white};
    }
  }

  &:hover {
    transform: scale(1.05);
    transition: transform 0.5s;
  }

  @media screen and (max-width: ${t.breakpoints.md.max}) {
    background-color: transparent;
  }
`
)

const CustomerExperienceSection = () => {
  const data = useStaticQuery(graphql`
    query {
      customerExperienceSlider: contentfulCustomerExperienceSlider {
        title
        ctaText
        cards {
          link
          preTitle
          title
          image {
            title
            gatsbyImageData(width: 768, placeholder: BLURRED)
          }
          smallImage
          text {
            text
          }
        }
      }
    }
  `)

  const cxSlider = data.customerExperienceSlider

  return (
    <CXSection>
      <Wrapper>
        <LeftWrapper>
          <TextContent>
            <Title as="h2" headingSize="3">
              {cxSlider.title}
            </Title>
            <CTAButton to={'/alexandria-kokemuksia'}>
              {cxSlider.ctaText} <ArrowRightIcon />
            </CTAButton>
          </TextContent>
        </LeftWrapper>
        <CardCarousel>
          {cxSlider.cards.map((card, index) => (
            <CustomerExperienceCard key={index} card={card} />
          ))}
        </CardCarousel>
      </Wrapper>
    </CXSection>
  )
}

export default CustomerExperienceSection
